@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HelloDenver';
  src: url('../public/hello-denver-display.denver-display-bold-bold-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1%);
  }
}

.animate-bounce-slow {
  animation: bounce 8s infinite ease-in;
}

@keyframes neonFlicker {
  0% {
    opacity: 1;
    filter: grayscale(100%);
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de, 0 0 50px #ff00de, 0 0 75px #ff00de;
  }
  10%, 20% {
    opacity: 0.3;
    filter: grayscale(100%);
    text-shadow: 0 0 2px #fff, 0 0 4px #ff00de;
  }
  30%, 50%, 70% {
    opacity: 0.7;
    filter: grayscale(100%);
    text-shadow: 0 0 3px #fff, 0 0 7px #ff00de, 0 0 12px #ff00de, 0 0 18px #ff00de;
  }
  80%, 90%, 100% {
    opacity: 1;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de, 0 0 50px #ff00de, 0 0 75px #ff00de;
    filter: none;
  }
}

.animate-neon-flicker {
  animation: neonFlicker 1s linear;
}

@keyframes zoomIn {
  0% {
    opacity: 0.05;
    background-size: 100%;
    filter: grayscale(100%);
  }
  70%, 90%, 95% {
    opacity: 0.1;
    background-size: 110%;
    filter: grayscale(100%);
  }
  85%, 92%, 98% {
    opacity: 0.2;
    background-size: 110%;
    filter: none;
  }
  100% {
    opacity: 0.3;
    background-size: 110%;
    filter: none;
  }
}

@keyframes flickering {
  0% {
    opacity: 0.05;
    filter: grayscale(100%);
  }
  70%, 90%, 95% {
    opacity: 0.1;
    filter: grayscale(100%);
  }
  85%, 92%, 98% {
    opacity: 0.2;
    filter: none;
  }
  100% {
    opacity: 0.3;
    filter: none;
  }
}

.tokyo-background {
  background-image: url('../public/wallpaper.jpg');
  background-position: center; /* Keeps the zoom centered */
  background-repeat: no-repeat;
  animation: zoomIn 4s ease-out forwards; /* Customize duration and timing function */
  background-size: cover;
  opacity: 0.1;
  filter: grayscale(100%);
}

@media (max-width: 820px) {
  .tokyo-background {
    background-image: url('../public/mobile-wallpaper.jpg');
  }
}

@media (max-width: 1440px) {
  .tokyo-background {
    animation: flickering 4s ease-out forwards;
  }
}

.glow {
  box-shadow: 5px 5px 25px rgba(255, 0, 242, 1);
}

.white-shadow {
  box-shadow: rgba(255, 255, 255, 0.66) 0px 19px 43px;
}

/* GET IN TOUCH button */
.streak-animation {
  position: relative;
  overflow: hidden;
}

.streak-animation::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0) 0%, rgba(255, 0, 242, 0.1) 50%, rgba(255, 255, 255, 0) 100%);
  animation: streak 4s linear infinite;
}

@keyframes streak {
  0% {
    left: -100%;
  }
  10% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}